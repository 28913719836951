<template>
    <div ref="prev" style="width: 100%; height: 100%">
        <b-row ref="bgprev" id="bg-prev" :style="myStyles">
            <div style="width: 100%">
                <div v-show="editDbMode" :style="editStyle">
                    <b-icon class="dragIcon" icon="arrows-move" font-scale="2" style="position: absolute; top: 5px; left: 5px" />
                    <div>
                        <p>{{opt.title}}</p>
                        <b-button variant="secondary" size="sm" @click="modalEdit = !modalEdit">Edit chart</b-button>
                    </div>
                </div>
                <div @click="editRules = !editRules" style="cursor: pointer; width: 100%">
                    <div ref="title" :style="titleStyle">
                        <div v-show="!editDbMode">{{opt.title}}</div>
                    </div>
                    <div>
                        <div ref="label" style="display: flex; flex-wrap: wrap; text-align: center">
                            <div v-show="!editDbMode" :style="labelStyleT">Inst&auml;llt</div>
                            <div v-show="!editDbMode" :style="labelStyleA">Aktuellt</div>
                        </div>
                    </div>
                    <div>
                        <div ref="display" style="display:flex; flex-wrap: wrap; text-align: center">
                            <div v-show="!editDbMode" :style="displayStyleT">{{tTempValue}} {{y1Unit}}</div>
                            <div v-show="!editDbMode" :style="displayStyleA">{{aTempValue}} {{y1Unit}}</div>
                        </div>
                    </div>
                    
                    <div prefix="time" :style="timeStyle">
                        <div v-show="!editDbMode">{{valueTime}}</div>
                    </div>
                </div>
            </div>
            <b-modal title="Edit component" v-model="modalEdit" @ok="handleOk" @cancel="handleCancel">
                <b-form-group>
                    <b-row>
                        <b-col cols="6">
                            <strong>Target temp storage</strong>
                            <b-form-select class="mt-1" v-model="tTempStorage" :options="storageOptions" :select-size="10"></b-form-select>
                        </b-col>
                        <b-col cols="6">
                            <strong>Actual temp storage</strong>
                            <b-form-select class="mt-1" v-model="aTempStorage" :options="storageOptions" :select-size="10"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-row>
                                <strong>Chart options</strong>
                            </b-row>
                            <b-row>
                                <strong>Value colors:</strong>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col class="d-flex flex-row" cols="6">
                                    <label>Less than target -0.5:</label>
                                </b-col>
                                <b-col cols="2">
                                    <color-picker v-model="opt.valueColor1" />
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col class="d-flex flex-row" cols="6">
                                    <label>Within target +-0.5:</label>
                                </b-col>
                                <b-col cols="2">
                                    <color-picker v-model="opt.valueColor2" />
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col class="d-flex flex-row" cols="6">
                                    <label>More than target +0.5:</label>
                                </b-col>
                                <b-col cols="2">
                                    <color-picker v-model="opt.valueColor3" />
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <b-col class="d-flex flex-row" cols="6">
                                    <label>Target value color:</label>
                                </b-col>
                                <b-col cols="2">
                                    <color-picker v-model="opt.targetValueColor" />
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col class="d-flex flex-row" cols="10" sm="9">
                                    <label for="title">Title:</label>
                                    <b-form-input class="ml-1" size="sm" id="title" type="text" v-model="opt.title"></b-form-input>
                                </b-col>
                                <b-col cols="2" sm="3">
                                    <color-picker v-model="opt.titleColor" />
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="10" sm="9">
                                    <label for="bkgColor">Time color:</label>
                                </b-col>
                                <b-col cols="2" sm="3">
                                    <color-picker id="bkgColor" v-model="opt.timeColor" />
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="10" sm="9">
                                    <label for="bkgColor">Background color:</label>
                                </b-col>
                                <b-col cols="2" sm="3">
                                    <color-picker id="bkgColor" v-model="opt.bkgColor" />
                                </b-col>
                            </b-row>
                            <b-row class="mt-2">
                                <b-col cols="5" sm="5">
                                    <label for="bdrColor">Border color:</label>
                                </b-col>
                                <b-col cols="5" sm="4">
                                    <b-form-checkbox v-model="opt.sameAsValue"
                                                     name="same-as-value">
                                        same as value
                                    </b-form-checkbox>
                                </b-col>
                                <b-col cols="2" sm="3">
                                    <color-picker v-show="!opt.sameAsValue" id="bdrColor" v-model="opt.bdrColor" />
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <strong>Configuration templates</strong>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label for="newTemplateName">New template:</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-input size="sm" id="newTemplateName" type="text" v-model="newTemplateName"></b-form-input>
                        </b-col>
                        <b-col cols="4">
                            <b-button variant="secondary" size="sm" @click="templateSave">Add</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label for="selectTemplate">Select template:</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-select size="sm" id="selectTemplate" type="text" :options="confTemplates" @change="templateChange" v-model="selectedTemplate" :select-size="1"></b-form-select>
                        </b-col>
                        <b-col cols="4">
                            <b-button variant="secondary" size="sm" @click="updateTemplate">Update</b-button>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox v-model="remove"
                                     name="checkbox-remove"
                                     value="yes"
                                     unchecked-value="no">
                        Remove this component
                    </b-form-checkbox>
                </b-form-group>
            </b-modal>
            <b-modal title="Edit rules" v-model="editRules" @ok="handleRulesOk" @cancel="handleRulesCancel">
                <b-form-group>
                    <b-row>
                        <b-col cols="6" class="px-2">
                            <label for="type">Collector plugin name: </label>
                        </b-col>
                        <b-col class="d-flex flex-row px-2" cols="6">
                            <b-form-input size="sm" id="name" type="text" v-model="opt.pluginName"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="px-2">
                            <label for="type">Thermostat name: </label>
                        </b-col>
                        <b-col class="d-flex flex-row px-2" cols="6">
                            <b-form-input size="sm" id="name" type="text" v-model="opt.thermostatName"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="px-2">
                            <strong>Thermostat rules:</strong>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1" v-for="(rule, index) in opt.rules" :key="rule.id">
                        <b-col cols="10" class="px-2">
                            <b-row>
                                <b-col cols="2">
                                    <label for="type">Type: </label>
                                </b-col>
                                <b-col class="d-flex flex-row px-1" cols="4">
                                    <b-form-select :options="ruleTypeOptions" size="sm" id="type" type="text" v-model="opt.rules[index].type"></b-form-select>
                                </b-col>
                                <b-col cols="2">
                                    <label for="temp">Temp: </label>
                                </b-col>
                                <b-col class="d-flex flex-row px-1" cols="4">
                                    <b-form-input size="sm" id="temp" type="number" v-model="opt.rules[index].temp"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row v-show="opt.rules[index].type!='Default'">
                                <b-col cols="2">
                                    <label v-show="opt.rules[index].type!='Until'" for="from">From: </label>
                                    <label v-show="opt.rules[index].type=='Until'" for="from">day: </label>
                                </b-col>
                                <b-col class="d-flex flex-row px-1" cols="4">
                                    <b-form-input v-show="opt.rules[index].type=='Timeperiod'" size="sm" id="from" type="time" v-model="opt.rules[index].from"></b-form-input>
                                    <b-form-input v-show="opt.rules[index].type=='Elprice'" size="sm" id="from" type="number" v-model="opt.rules[index].from"></b-form-input>
                                    <b-form-input v-show="opt.rules[index].type=='Until'" size="sm" id="from" type="date" v-model="opt.rules[index].days"></b-form-input>
                                </b-col>
                                <b-col cols="2">
                                    <label v-show="opt.rules[index].type!='Until'" for="to">To: </label>
                                    <label v-show="opt.rules[index].type=='Until'" for="to">hour: </label>
                                </b-col>
                                <b-col class="d-flex flex-row px-1" cols="4">
                                    <b-form-input v-show="opt.rules[index].type=='Timeperiod'" size="sm" id="to" type="time" v-model="opt.rules[index].to"></b-form-input>
                                    <b-form-input v-show="opt.rules[index].type=='Elprice'" size="sm" id="to" type="number" v-model="opt.rules[index].to"></b-form-input>
                                    <b-form-input v-show="opt.rules[index].type=='Until'" size="sm" id="to" type="time" v-model="opt.rules[index].hours"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="2">
                            <b-button variant="secondary" size="sm" @click="removeRule(index)">X</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="px-2">
                            <b-button variant="secondary" size="sm" @click="addRule">Add</b-button>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-modal>
        </b-row>
    </div>
</template>

<script>
    import { bus } from '@/main.js';
    import { uuid } from 'vue-uuid';
    import colorPicker from '@/components/Dashboard/colorPicker';
    import Amplify from 'aws-amplify';
    export default {
        name: 'thermostat',
        props: ['dbid', 'itemid', 'comp'],
        data() {
            return {
                x: 0,
                y: 0,
                modalEdit: false,
                editRules: false,
                remove: 'no',
                opt: {},
                selectedtTempStorage: '',
                selectedaTempStorage: '',
                seriesConf: [],
                yaxisOptions: ['y1', 'y2'],
                ruleTypeOptions:['Default', 'Timeperiod', 'Elprice', 'Until'],
                y1Unit: 'tbd',
                y2Unit: 'tbd',
                subTitle: 'tbd',
                tTempValue: 0,
                aTempValue: 0,
                value: 0,
                valueTime: new Date().toLocaleTimeString(),
                series: [{
                    name: 'Waiting...',
                    data: [0],
                    dataLabels: {
                        overflow: 'allow',
                        padding: 0,
                        allowOverlap: true,
                        borderWidth: 0
                    }
                }],
                updateArgs: [true, true],
                newTemplateName: '',
                selectedTemplate: '-'
            }
        },
        computed: {
            myStyles() {
                let bdrColor = this.opt.bdrColor
                if (this.opt.sameAsValue) {
                    for (let b of this.opt.plotBands) {
                        if (this.value >= b.from && this.value < b.to) bdrColor = b.color
                    }
                }
                return {
                    'width': '100%',
                    'height': '100%',
                    'background-color': (this.editDbMode) ? 'rgba(255, 255, 255, 0.2)' : this.opt.bkgColor,
                    'border-style': (this.editDbMode) ? 'solid' : 'solid',
                    'border-color': (this.editDbMode) ? '#eaeaea' : bdrColor,
                    'border-width': (this.editDbMode) ? '1px' : '1px',
                    'border-radius': (this.editDbMode) ? '0px' : '5px',
                    'display': 'flex',
                    'align-items': 'center',
                    'justify-content': 'space-around',
                    'margin-left': '0px',
                    'margin-right': '0px',
                    'padding': '0px'
                }
            },
            editStyle() {
                return {
                    'width': '100%',
                    'height': '100%',
                    'display': 'flex',
                    'align-items': 'center',
                    'justify-content': 'space-evenly'
                }
            },
            mySize() {
                return {
                    width: '100%',
                    height: '100%'
                }
            },
            titleStyle() {
                let size = Math.round(parseFloat(this.y) / 4.5) + 'px'
                return {
                    'position': 'absolute',
                    'top': '2px',
                    'left': '5px',
                    'color': this.opt.titleColor,
                    'font-size': size,
                    'font-weight': 'bold',
                    'line-height': size,
                    'margin-top': '0px'
                }
            },
            timeStyle() {
                let size = Math.round(parseFloat(this.y) / 6) + 'px'
                return {
                    'position': 'absolute',
                    'bottom': '2px',
                    'left': '5px',
                    'color': this.opt.timeColor,
                    'font-size': size,
                    'line-height': size,
                    'margin-bottom': '2px'
                }
            },
            labelStyleT() {
                let color = this.opt.targetValueColor
                let size = Math.round(parseFloat(this.y) / 7) + 'px'
                return {
                    'color': color,
                    'font-size': size,
                    'width': '50%'
                }
            },
            labelStyleA() {
                let color = this.opt.valueColor2
                if (this.aTempValue < this.tTempValue - 0.5) color = this.opt.valueColor1
                if (this.aTempValue > this.tTempValue + 0.5) color = this.opt.valueColor3
                let size = Math.round(parseFloat(this.y) / 7) + 'px'
                return {
                    'color': color,
                    'font-size': size,
                    'width': '50%'
                }
            },
            statIconStyle() {
                return {
                    'cursor': 'pointer',
                    'position': 'absolute',
                    'z-index': 100,
                    'color': '#02abe9'
                }

            },
            displayStyleT() {
                let color = this.opt.targetValueColor
                let size = Math.round(parseFloat(this.y) / 4) + 'px'
                return {
                    'color': color,
                    'font-size': size,
                    'font-weight': 'bolder',
                    'flex': '1',
                    'line-height': size
                }
            },
            displayStyleA() {
                let color = this.opt.valueColor2
                if (this.aTempValue < this.tTempValue - 0.5) color = this.opt.valueColor1
                if (this.aTempValue > this.tTempValue + 0.5) color = this.opt.valueColor3
                let size = Math.round(parseFloat(this.y) / 4) + 'px'
                return {
                    'color': color,
                    'font-size': size,
                    'font-weight': 'bolder',
                    'flex': '1',
                    'line-height': size
                }
            },
            storageOptions() {
                let options = []
                for (let storage of this.$store.getters.storages) {
                    options.push({ value: storage.placeId, text: storage.placeName })
                }
                return options
            },
            confTemplates() {
                let options = []
                for (let template of this.$store.getters.confTemplates) {
                    if (template.type == this.comp.name) options.push({ value: template.name, text: template.name })
                }
                return options
            },
            tTempStorage: {
                get() {
                    if (this.selectedtTempStorage == null && typeof this.comp.config.tTempstorage !== 'undefined') {
                        return this.comp.config.tTempstorage
                    } else {
                        return this.selectedtTempStorage
                    }
                },
                set(newStorage) {
                    // Update seriesConf
                    //this.seriesConf = [{ storageId: newStorages, axis: 'y1', color: '#000000' }]
                    console.log("newStorage: " + newStorage)
                    this.selectedtTempStorage = newStorage
                    this.selectedStorages = [newStorage, this.selectedaTempStorage]
                    this.opt.collectorId = this.$store.getters.sources.find(x => x.placeId === newStorage).loggerId
                }
            },
            aTempStorage: {
                get() {
                    if (this.selectedaTempStorage == null && typeof this.comp.config.aTempstorage !== 'undefined') {
                        return this.comp.config.aTempstorage
                    } else {
                        return this.selectedaTempStorage
                    }
                },
                set(newStorage) {
                    // Update seriesConf
                    //this.seriesConf = [{ storageId: newStorages, axis: 'y1', color: '#000000' }]
                    this.selectedaTempStorage = newStorage
                    this.selectedStorages = [this.selectedtTempStorage, newStorage]
                }
            },
            editDbMode() {
                return this.$store.getters.editDbMode;
            }
        },
        created() {
            if (typeof this.comp.config.series !== 'undefined') {
                this.seriesConf = JSON.parse(JSON.stringify(this.comp.config.series))
            }
            if (typeof this.comp.config.storages !== 'undefined') {
                this.selectedStorages = JSON.parse(JSON.stringify(this.comp.config.storages))
            }
            if (typeof this.comp.config.tTempStorage !== 'undefined') this.selectedtTempStorage = JSON.parse(JSON.stringify(this.comp.config.tTempStorage))
            if (typeof this.comp.config.aTempStorage !== 'undefined') this.selectedaTempStorage = JSON.parse(JSON.stringify(this.comp.config.aTempStorage))
            if (typeof this.comp.config.opt !== 'undefined') {
                this.opt = JSON.parse(JSON.stringify(this.comp.config.opt))
                if (typeof this.opt.valueColor1 === 'undefined') this.opt.valueColor1 = '#eaeaea';
                if (typeof this.opt.valueColor2 === 'undefined') this.opt.valueColor2 = '#eaeaea';
                if (typeof this.opt.valueColor3 === 'undefined') this.opt.valueColor3 = '#eaeaea';
                if (typeof this.opt.targetValueColor === 'undefined') this.opt.targetValueColor = '#eaeaea';
            } else {
                this.opt = {
                    plotBands: [],
                    titleColor: '#eaeaea',
                    timeColor: '#eaeaea',
                    title: 'tbd',
                    sameAsValue: true,
                    rules: [],
                    valueColor1: '#eaeaea',
                    valueColor2: '#eaeaea',
                    valueColor3: '#eaeaea',
                    targetValueColor: '#eaeaea'
                }
            }
            this.opt.rules = JSON.parse(JSON.stringify(this.opt.rules.filter((rule) => {
                return !(rule.type == 'Until') || new Date() < new Date(rule.days + " " + rule.hours)
            })))
        },
        beforeDestroy() {
            bus.$off();
        },
        mounted() {
            this.x = this.$refs.prev.clientWidth;
            this.y = this.$refs.prev.clientHeight;
            bus.$on('itemResized', (newSize) => {
                if (newSize.i == this.itemid) {
                    this.x = newSize.wpx
                    this.y = newSize.hpx
                }
            })
            bus.$on('newData', () => {
                if (this.$store.getters.data.length > 0 && typeof this.comp.config.storages !== 'undefined' && this.comp.config.storages.length > 0) {
                    if (this.selectedtTempStorage !== undefined && this.selectedtTempStorage !== null) {
                        let metricId = this.$store.getters.storages.find(x => x.placeId === this.selectedtTempStorage).metricId
                        this.y1Unit = this.$store.getters.metrics.find(x => x.id === metricId).unit
                        this.subTitle = new Date(this.$store.getters.data.find(x => x.placeId === this.selectedtTempStorage).timeNow).toLocaleString()
                        this.tTempValue = Math.round(this.$store.getters.data.find(x => x.placeId === this.selectedtTempStorage).valueNow * 10) / 10
                        this.valueTime = new Date(this.$store.getters.data.find(x => x.placeId === this.selectedtTempStorage).timeNow).toLocaleTimeString()
                    }
                    if (this.selectedaTempStorage !== undefined && this.selectedaTempStorage !== null) {
                        this.aTempValue = Math.round(this.$store.getters.data.find(x => x.placeId === this.selectedaTempStorage).valueNow * 10) / 10
                    }
                }
            });
        },
        watch: {
            // whenever question changes, this function will run
            editRules(edit) {
                if (edit) {
                    this.opt.rules = JSON.parse(JSON.stringify(this.opt.rules.filter((rule) => {
                        return !(rule.type == 'Until') || new Date() < new Date(rule.days + " " + rule.hours)
                    })))
                }
            }
        },
        methods: {
            handleOk() {
                let payload = {
                    dbid: this.dbid,
                    itemid: this.itemid,
                    newConfig: {
                        storages: this.selectedStorages,
                        /*chartOptions: this.chartOptions,*/
                        opt: this.opt,
                        tTempStorage: this.selectedtTempStorage,
                        aTempStorage: this.selectedaTempStorage
                    }
                }
                this.$store.commit('updateCompConfig', payload);
                
                if (this.remove == 'yes') {
                    let payload = {
                        dbid: this.dbid,
                        itemid: this.itemid
                    }
                    this.remove = 'no'
                    this.$emit('removed', payload)
                }
                this.$store.commit('updateStorageIds', this.dbid)
            },
            handleCancel() {
                if (typeof this.comp.config.series !== 'undefined') {
                    this.seriesConf = JSON.parse(JSON.stringify(this.comp.config.series))
                }
                if (typeof this.comp.config.storages !== 'undefined') {
                    this.selectedStorages = JSON.parse(JSON.stringify(this.comp.config.storages))
                }
                if (typeof this.comp.config.opt !== 'undefined') {
                    this.opt = JSON.parse(JSON.stringify(this.comp.config.opt))
                }
            },
            handleRulesOk() {
                let payload = {
                    dbid: this.dbid,
                    itemid: this.itemid,
                    newConfig: {
                        storages: this.selectedStorages,
                        opt: this.opt,
                        tTempStorage: this.selectedtTempStorage,
                        aTempStorage: this.selectedaTempStorage
                    }
                }
                this.$store.commit('updateCompConfig', payload);
                this.$store.dispatch('updateUserConfig')
                let message = {
                    loggerId: this.opt.collectorId,
                    plugins: [
                        {
                            receiver: this.opt.pluginName,
                            topic: 'configuration',
                            message: {
                                configName: 'thermostat',
                                config: {
                                    name: this.opt.thermostatName,
                                    rules: this.opt.rules
                                }
                            }
                        }
                    ]
                }
                console.log(message)
                Amplify.Auth.currentAuthenticatedUser()
                    .then(async () => {
                        this.$store.commit('loadingStarted')
                        try {
                            await Amplify.API.post("l4a-cbe", '/dev/pluginMessageMgmt', { body: message })
                            this.$store.commit('loadingEnded')
                        } catch (err) {
                            console.log(err)
                            this.$store.commit('loadingEnded')
                        }

                    })
                    .catch(err => console.log(JSON.stringify(err, null, 2)))
                this.editRules = false
            },
            handleRulesCancel() {
                if (typeof this.comp.config.opt !== 'undefined') {
                    this.opt = JSON.parse(JSON.stringify(this.comp.config.opt))
                }
                this.editRules = false
            },
            templateChange() {
                let tmpl = JSON.parse(JSON.stringify(this.$store.getters.confTemplates.find(x => x.name === this.selectedTemplate).tmpl))
                tmpl.opt.title = this.opt.title
                this.opt = tmpl.opt
            },
            templateSave() {
                let opt = JSON.parse(JSON.stringify(this.opt))
                opt.title = 'tbd'
                let payload = {
                    name: this.newTemplateName,
                    type: this.comp.name,
                    tmpl: {
                        opt: opt
                    }
                }
                this.$store.commit('addConfTemplate', payload)
                this.selectedTemplate = this.newTemplateName
                this.newTemplateName = ''
            },
            updateTemplate() {
                let opt = JSON.parse(JSON.stringify(this.opt))
                opt.title = 'tbd'
                let payload = {
                    name: this.selectedTemplate,
                    type: this.comp.name,
                    tmpl: {
                        opt: opt
                    }
                }
                this.$store.commit('updateConfTemplate', payload)
            },
            addPlotband() {
                this.opt.plotBands.push({ color: '#000000' })
            },
            removePlotband(index) {
                this.opt.plotBands.splice(index, 1)
            },
            addRule() {
                this.opt.rules.push({ id: uuid.v4(), type: 'time', temp: 0, from: 0, to: 0 })
            },
            removeRule(index) {
                this.opt.rules.splice(index, 1)
            }
        },
        components: {
            'color-picker': colorPicker
        }
    };
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>